import React, { useState } from 'react';

export default function InfoBox ({ini}) {

  const [currentImage, setCurrentImage] = useState(1);

  return (
    <div className="info-box">
      <img className='image-big' src={`./assets/img/${currentImage}.jpg`} alt='cover thumbnail image'/>
      <div className="images">
        <img onClick={() => setCurrentImage(1)} src="./assets/img/1.jpg" alt='cover thumbnail image'/>
        <img onClick={() => setCurrentImage(2)} src="./assets/img/2.jpg" alt='cover thumbnail image'/>
        <img onClick={() => setCurrentImage(3)} src="./assets/img/3.jpg" alt='cover thumbnail image'/>
      </div>
      <h3>Kayra - Ömrümün Son Güzel Günleri 33 Devir Plak (2. Baskı)</h3>
      <ul>
        <li><span>Bu form <u>Kayra - Ömrümün Son Güzel Günleri</u> plağı <b>ikinci baskı</b> için ön sipariş formudur.</span></li>
        <li><span>İkinci baskılar <b>koleksiyoner <u>numarasız</u></b> olarak üretilecektir, <b>imzalı değildir</b>.</span></li>
        <li><span>Bütün plaklar <b>15x21cm Fujifilm Crystal Clear</b> kağıda basılmış tek tek el ile soğuk damga <u>mühürlenmiş</u> ve <u>kaşelenmiş fotoğraf hediyesi</u> ile beraber gönderilecektir.</span></li>
        <li><span>Üretim ancak ön sipariş tamamlandığında yapılabileceğinden kargoya teslim <b>Ocak 2025</b>’te mümkün olacaktır.</span></li>
        <li><span>Satış fiyatına kargo ücreti <b>dahil değildir</b>. Siparişlerinizin gönderimi <b>Sürat Kargo</b> ile yapılacaktır. Tüm Türkiye’ye alıcı ödemeli gönderim bedeli <b><u>82 TL</u></b>’dir.</span></li>
        <li><span>Form aracılığı ile paylaştığınız kişisel bilgiler üçüncü taraf kargo firmaları ile paketlerin size ulaştırılması amacı ile paylaşılacaktır. Bu formu doldururken paylaştığınız cep telefonu bilginizi size siparişiniz ile ilgili SMS’leri göndermek için <u>Dijital Dağıtım</u> ve <u>somilk</u> tarafından kullanılabileceğini kabul etmiş sayılırsınız.</span></li>
        {Number.isInteger(ini?.has_stock) ? (
          <li className="stock"><span className="rainbow-text">Kalan ürün: <b>{ini?.has_stock}</b></span></li>
        ) : null}
      </ul>
      <div className="contact-info">
        <h4>İletişim</h4>
        <ul>
          <li><img width={16} src="./assets/svg/email.svg" /> <span><a href="mailto:osgg@somilk.co" target='_blank'>osgg@somilk.co</a></span></li>
          <li><img width={16} src="./assets/svg/instagram.svg" /> <span><a href="https://instagram.com/somilkco" target='_blank'>somilkco</a></span></li>
        </ul>
      </div>
      <div className="branding">
        bir
        <a href="https://www.dijitaldagitim.com.tr" target='_blank'>
          <img src="./assets/svg/dijitaldagitim.svg"/>
        </a>
        deneyimidir.
      </div>
    </div>);
}