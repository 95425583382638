import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

export default function RedirectInfo ({ clientSecret }) {
  const [message, setMessage] = useState('Ödeme bilgisi yükleniyor...');
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Ön siparişinizi başarı ile kaydettik. Süreçlerle ilgili form üzerinden paylaşmış olduğunuz cep telefonu üzerinden size SMS ile bilgi vereceğiz.');
          break;
        case 'processing':
          setMessage('Ödeme halen işleniyor.');
          break;
        case 'requires_payment_method':
          setMessage('Ödeme başarısız, lütfen tekrar deneyiniz.');
          break;
        default:
          setMessage('Bilinmeyen bir problem oluştu.');
          break;
      }
    });
  }, [stripe]);

  return (<div className="message">{message}</div>);
};
