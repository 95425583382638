import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';
import PaymentInfo from './PaymentInfo';
import InfoBox from './InfoBox';
import './App.css';
import { isLocalHost } from './constants';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  isLocalHost
    ? 'pk_test_51OoYpIHBvuIPAQjitIAXsVFpH6AmMQz5tYJwQkBHQViPdUj7vWwCm6WSuhA5exlE1QiNHsHQCigLTjR3KndSRLqW00IpDTGO8d'
    : 'pk_live_51OoYpIHBvuIPAQjiMZ7NoHwVhEGEcBu0DMGEJQYpZVcsQVCaybNRnglEw8sHDGGDqQ8xztzmR30THVpnyW4qmYWf00KOgclqdF'
  , { locale: 'tr' });
// const stripePromise = loadStripe("pk_live_51KQa6vIHlI3INyvtNRcRwPQAPPGOuC7K3dEVZpdsumAoI7COBGTQ7kNh8UQIn9yVmMGoIWXXdqCTUmKDSaXfsSDW00on4zp9FS");

export default function App () {
  const clientSecretParam = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret',
  );

  const isRedirected = !!clientSecretParam?.length;

  const [clientSecret, setClientSecret] = useState('');
  const [ini, setIni] = useState({});
  const [id, setId] = useState('');

  const handlePI = (mode, data) => {
    return fetch(`./api/${mode}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...data, id }),
    })
      .then((res) => res.json())
      .then((data) => {
        setId(data.id);
        setClientSecret(data.clientSecret);
      });
  };

  const logError = (data) => {
    fetch('/api/log', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...clientSecret,
        ...data,
      }),
    }).then(() => {});
  };

  useEffect(() => {
    if (isRedirected) {
      setClientSecret(clientSecretParam);
      return;
    }

    fetch(`./api/ini`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        setIni(data);

        // Create PaymentIntent as soon as the page loads
        if (data?.has_stock) {
          handlePI('create', { count: 1 });
        }
      });
  }, []);

  const updatePI = data => {
    return handlePI('update', data);
  };

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  console.log(isRedirected, ini.has_stock);
  if (!isRedirected && ini?.has_stock <= 0 ) {
    return (
      <div className="App">
        <div className="message" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src="./assets/img/sold_out.png" width='100%' style={{marginBottom: 16, maxWidth: 200}}/>
          <span style={{marginBottom: 16}}>
            Göstermiş olduğunuz yoğun ilgiye çok teşekkürler.<br />
            <br />
            500 adet sınırlı sayıda üretilecek olan plakların ön siparişini tamamladık. Sizleri bilgilendirmeye devam edeceğiz.
          </span>
          <iframe style={{borderRadius: 12}}
                  src="https://open.spotify.com/embed/album/36wDwn7fld4l2Qw77qXnvX?utm_source=generator" width="100%"
                  height="352" frameBorder="0" allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"></iframe>
        </div>
      </div>
    )
  }

  if(!clientSecret) {
    return (
      <div className="App">
        <div className="message">
          <h2 className="title">Yükleniyor...</h2>
          <span>Önsipariş formu yükleniyor, lütfen bekleyeniz.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      {isRedirected ? (
        <Elements options={options} stripe={stripePromise}>
          <PaymentInfo clientSecret={clientSecret}/>
        </Elements>
      ) : (
        <Elements options={options} stripe={stripePromise}>
          <InfoBox ini={ini}/>
          <CheckoutForm setIni={setIni} updatePI={updatePI} logError={logError}/>
        </Elements>
      )}
    </div>
  );
}